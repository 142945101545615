<template>
  <div class="interaction-intro">
    <div class="interaction-intro__stage" ref="stage">
      <h1 v-html="content.headline" ref="h1"></h1>
      <h3 v-if="content.subline" v-html="content.subline" ref="h3"></h3>
      <h2 :class="{ 'small': content.small_copy }" v-html="getCopy()" ref="h2">

      </h2>

      <template v-if="content.disclaimer">
        <a class="disclaimer-link" ref="disclaimer_link" href="#" @mouseover="showDisclaimer"
          @mouseout="hideDisclaimer">DISCLAIMER</a>

        <div class="interaction-intro__disclaimer" ref="disclaimer">
          <span v-html="content.disclaimer"></span>
        </div>
      </template>

      <img :src="content.qrcode" alt="" v-if="content.qrcode" class="interaction-intro__qrcode" ref="qrcode">
      <Button class="button--small" ref="link" @click.native="hide">{{
          content.button
      }}</Button>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import Button from "./../../form/Button";
import isMobile from "ismobilejs"
export default {
  components: { Button },
  name: "InteractionIntro",
  data() {
    return {};
  },
  props: {
    content: undefined
  },
  methods: {
    getCopy() {
      if (!isMobile().any) {
        return this.content.copy;
      } else {
        return this.content.copy_mobile ? this.content.copy_mobile : this.content.copy;
      }
    },
    hideDisclaimer() {
      gsap.to(this.$refs.disclaimer, { autoAlpha: 0, y: 30, duration: 0.5 });
    },
    showDisclaimer() {
      gsap.to(this.$refs.disclaimer, { autoAlpha: 1, y: 0 });
    },
    hide() {
      gsap.to(this.$el, { delay: 0.25, overwrite: true, autoAlpha: 0 });
      gsap.to(this.$refs.h1, { duration: 0.25, overwrite: true, autoAlpha: 0 });
      gsap.to(this.$refs.h2, { duration: 0.25, overwrite: true, autoAlpha: 0 });
      if (this.$refs.h3) gsap.to(this.$refs.h3, { duration: 0.25, overwrite: true, autoAlpha: 0 });

      if (this.$refs.disclaimer_link) gsap.to(this.$refs.disclaimer_link, { autoAlpha: 0 });
      if (this.$refs.qrcode) gsap.to(this.$refs.qrcode, { autoAlpha: 0 });

      gsap
        .to(this.$refs.link.$el, {
          duration: 0.25,
          overwrite: true,
          autoAlpha: 0
        })
        .then(() => {
          if (this.$refs.qrcode) {
            this.$emit("skipping");
          } else {
            this.$emit("hiding");
          }
        });
    }
  },
  mounted() {
    this.$nextTick(() => {
      gsap.set(this.$el, { autoAlpha: 0 });
      gsap.set(this.$refs.h1, { y: 10, autoAlpha: 0 });
      gsap.set(this.$refs.h2, { autoAlpha: 0 });
      if (this.$refs.h3) gsap.set(this.$refs.h3, { autoAlpha: 0 });
      gsap.set(this.$refs.link.$el, { autoAlpha: 0 });
      if (this.$refs.disclaimer_link) gsap.set(this.$refs.disclaimer_link, { autoAlpha: 0 });
      if (this.$refs.qrcode) gsap.set(this.$refs.qrcode, { autoAlpha: 0 });

      const tl = gsap.timeline();
      tl.to(this.$el, { duration: 0.7, autoAlpha: 1 });
      tl.to(this.$refs.h1, { autoAlpha: 1 }, 0.7);
      tl.to(this.$refs.h1, { duration: 1, y: 0 }, 0.7);
      tl.to(this.$refs.h2, { autoAlpha: 1 }, 1);
      if (this.$refs.h3) tl.to(this.$refs.h3, { autoAlpha: 1 }, 1);

      tl.to(this.$refs.link.$el, { autoAlpha: 1 }, 1);
      if (this.$refs.disclaimer_link) tl.to(this.$refs.disclaimer_link, { autoAlpha: 1 }, 1);
      if (this.$refs.qrcode) tl.to(this.$refs.qrcode, { autoAlpha: 1 }, 1);

      //tl.to(this.$refs.icon, { duration:3,rotation: 180 ,ease:"Expo.easeInOut",},5).then(this.hide);
    });
  }
};
</script>
